import React, { useEffect, useState } from 'react'
import Cards from './Cards'
import ApiHelper from '../../Helper/ApiHelper';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GppBadIcon from '@mui/icons-material/GppBad';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import moment from 'moment';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useSnackbar } from 'notistack';
import Loader from '../../Components/Loader';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PolylineIcon from '@mui/icons-material/Polyline';


export default function Dashboard() {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [from, setfrom] = useState(moment().format("YYYY-MM-DD"));
    const [to, setto] = useState(moment().format("YYYY-MM-DD"));
    const [Loading, setLoading] = useState(false);
    const [data, setdata] = useState([]);

    const DashboardHendler = async (e) => {
        let f = from
        let t = to
        if (e && e.target.id === "start_date") {
            f = e.target.value
            setfrom(e.target.value)
        }
        if (e && e.target.id === "end_date") {
            t = e.target.value
            setto(e.target.value)
        }
        if (!moment(t).isSameOrAfter(f)) {
            return showSnackbar("Invalide Date Formet")
        }
        try {
            setLoading(true)
            const result = await ApiHelper.GetDes(f, t)
            setLoading(false)
            if (result.status === 200) {
                return setdata(result.data.data)
            }
            return showSnackbar("Somthing Went Wrong", { variant: "error" })
        } catch (err) {
            setLoading(false)
            console.log(err)
            showSnackbar("Internal Server Error", { variant: "error" })
        }
    }


    useEffect(() => {
        DashboardHendler()
        // eslint-disable-next-line
    }, []);


    return (
        <>
            {
                Loading ? <Loader /> : ""
            }
            <div className="d-flex align-items-center justify-content-between">
                <h4 className='fw-bold'>
                    Analytics
                </h4>
                <div className='d-flex align-items-center' style={{ gap: "1rem" }}>
                    <div>
                        <label htmlFor="start_date" className='fw-bold text-muted'>From &nbsp; </label>
                        <input type="date" id='start_date' style={{ width: "10rem" }} value={from} onChange={DashboardHendler} />
                    </div>
                    <div>
                        <label htmlFor="end_date" className='fw-bold text-muted'>To &nbsp; </label>
                        <input type="date" id='end_date' style={{ width: "10rem" }} value={to} onChange={DashboardHendler} />
                    </div>
                </div>
            </div>
            <div className="row mt-5" style={{ rowGap: "1rem" }}>
                <div className="col-md-4 col-sm-12">
                    <Cards title="NEW" count={data.find((x) => x.status === "new") ? data.find((x) => x.status === "new").TotalOrders : 0} icon={<AutorenewIcon />} variant="dark" />
                </div>
                <div className="col-md-4 col-sm-12">
                    <Cards title="ACCEPTED" count={data.find((x) => x.status === "verify") ? data.find((x) => x.status === "verify").TotalOrders : 0} icon={<VerifiedIcon />} variant="info" />
                </div>
                <div className="col-md-4 col-sm-12">
                    <Cards title="DISPATCH" count={data.find((x) => x.status === "dispatch") ? data.find((x) => x.status === "dispatch").TotalOrders : 0} icon={<LocalShippingIcon />} variant="success" />
                </div>
                <div className="col-md-4 col-sm-12">
                    <Cards title="HOLD" count={data.find((x) => x.status === "hold") ? data.find((x) => x.status === "hold").TotalOrders : 0} icon={< PauseCircleIcon />} variant="warning" />
                </div>
                <div className="col-md-4 col-sm-12">
                    <Cards title="CANCEL" count={data.find((x) => x.status === "cancel") ? data.find((x) => x.status === "cancel").TotalOrders : 0} icon={<GppBadIcon />} variant="error" />
                </div>
                <div className="col-md-4 col-sm-12">
                    <Cards title="RETURNED" count={data.find((x) => x.status === "returned") ? data.find((x) => x.status === "returned").TotalOrders : 0} icon={<KeyboardReturnIcon />} variant="#800000" />
                </div>
                <div className="col-md-4 col-sm-12">
                    <Cards title="DELIVERED" count={data.find((x) => x.status === "delivered") ? data.find((x) => x.status === "delivered").TotalOrders : 0} icon={<MapsHomeWorkIcon />} variant="secondary" />
                </div>
                <div className="col-md-4 col-sm-12">
                    <Cards title="TOTAL" count={data.find((x) => x.status === "total") ? data.find((x) => x.status === "total").TotalOrders : 0} icon={<PolylineIcon />} variant="#ff248d" />
                </div>
            </div>
        </>
    )
}
