import { Chip, Pagination } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import ApiHelper from '../../Helper/ApiHelper'
import moment from 'moment';
import Loader from '../../Components/Loader';
import { useSnackbar } from "notistack"
import { Link, useNavigate } from "react-router-dom"

export default function HoldOrders() {
    const navigater = useNavigate()
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [Loading, setLoading] = useState(false);
    const [CurrentPage, setCurrentPage] = useState(1);
    const [Orders, setOrders] = useState([])
    const [Page, setPage] = useState({
        from: 0,
        to: 10,
    });
    const [TotalPage, setTotalPage] = useState(1);
    const [Products, setProducts] = useState([]);

    const fetchAllProdcuts = async () => {
        try {
            setLoading(true)
            const products = await ApiHelper.FetchProdcuts()
            setLoading(false)
            setProducts(products.data.Products)
        } catch (err) {
            setProducts([])
            showSnackbar("Failed To Fetch Prodcuts.", { variant: "error" })
        }
    }


    useEffect(() => {
        fetchAllProdcuts()
        // eslint-disable-next-line
    }, []);

    const FetchOrders = async () => {
        try {
            let from = decodeURI(window.location.search)
            from = from.split("=")[1]
            from = Number(from)
            from = Number.isInteger(from) ? (from - 1) * Page.to : 0
            if (from === 0) {
                navigater(window.location.pathname)
            }
            setLoading(true)
            const result = await ApiHelper.FetchOrderByStatus(from, Page.to, "hold")
            setLoading(false)
            setOrders(result.data.Orders)
            setTotalPage(result.data.TotalPage)
            let curentpage = from === 0 ? 1 : (from / 10) + 1
            setCurrentPage(Number.isInteger(curentpage) ? curentpage : 1)
        } catch (err) {
            console.log(err)
            setOrders([])
            setLoading(false)
            showSnackbar("Failed To Fetch Orders.", { variant: "error" })
        }
    }

    useEffect(() => {
        FetchOrders()
        // eslint-disable-next-line
    }, [Page]);

    const CustomPagination = () => {
        return (
            <Pagination
                color="secondary"
                count={TotalPage}
                page={CurrentPage}
                onChange={(e, value) => {
                    if (value !== 1) {
                        let from = Page.to * (value - 1)
                        navigater(window.location.pathname + `?row=${(from / Page.to) + 1}`)
                        setPage({ ...Page, from: from })
                    } else {
                        navigater(window.location.pathname + `?row=${1}`)
                        setPage({ ...Page, from: 0 })
                    }
                }}
            />
        );
    }


    const Columns = [
        {
            field: "created_at", sortable: false, headerName: "ORDER DATE", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return <>
                    <div className='d-flex flex-column w-100'>
                        {/* <span>{moment(cell.row.tbl_orders ? cell.row.tbl_orders.created_at : cell.value).utc().format("DD-MMM-YY")} {moment(cell.row.tbl_orders ? cell.row.tbl_orders.created_at : cell.value).utc().format("LT")}</span> */}
                        {/* <span>{moment(moment(cell.row.tbl_orders ? cell.row.tbl_orders.created_at : cell.value).utc().format(), "YYYY-MM-DDThh:mm:ss").fromNow()} {moment(cell.row.tbl_orders ? cell.row.tbl_orders.created_at : cell.value).utc().format("LT")}</span> */}
                        <span>{moment(cell.row.tbl_orders ? cell.row.tbl_orders.created_at : cell.value).utc().local().format("DD-MMM-YY hh:mm:ss A")} </span>

                    </div>
                </>
            }
        },
        {
            field: "order_id", sortable: false, headerName: "ORDER INFO", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return (<>
                    <div className="d-flex flex-column">
                        <div>
                            <Link className='text-danger' onClick={() => localStorage.lastUrl = window.location.href} style={{ textDecoration: "underline" }} to={"/order/" + cell.value}>
                                {cell.value}
                            </Link>
                        </div>
                        {/* <div>{cell.row.tbl_orders.customer_name}</div> */}
                    </div>
                </>)
            }
        },
        { field: "customer_Name", sortable: false, headerName: "CUSTOMER NAME", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => cell.row.tbl_orders?.customer_name },

        {
            field: "crmid", sortable: false, headerName: "CRM ID", width: 150, headerClassName: "super-app-theme--header", renderCell: (cell) => (
                <>
                    <span className='fw-bold'>
                        {
                            cell.value ? cell.value : "N/A"
                        }
                    </span>
                </>
            )
        },
        { field: "disposition_desc", sortable: false, headerName: "REMARK", width: 200, headerClassName: "super-app-theme--header" },
        {
            field: "payment_status", sortable: false, headerName: "PAYMENT STATUS", width: 150, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return (<>
                    <Chip style={{ width: "200px" }} color={cell.row.tbl_orders.payment_status === "pending" ? "warning" : cell.row.tbl_orders.payment_status === "reject" ? "error" : "success"} label={cell.row.tbl_orders.payment_status.toUpperCase()} />
                </>)
            }
        },
        {
            field: "product", headerName: "PRODUCT", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                let Product = Products && Products.find((x) => x.id === cell.row.tbl_orders.product).name
                return Product.split("-")[0].length > 0 ? Product.split("-")[0] : Product
            }
        },
        {
            field: "total_price", sortable: false, headerName: "ORDER TOTAL", width: 150, headerClassName: "super-app-theme--header", renderCell: (cell) => (
                <>
                    <Chip style={{ width: "200px" }} color="info" label={`${cell.row.tbl_orders.total_price} - ${cell.row.tbl_orders.payment_mode}`} />
                </>
            )
        },
        { field: "customer_phone", sortable: false, headerName: "CONTACT", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => cell.row.tbl_orders.customer_phone },
        {
            field: "state", sortable: false, headerName: "DESTINATION", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => (
                <div className="d-flex flex-column">
                    <div>{cell.row.tbl_orders.state}</div>
                    <div>Pin: {cell.row.tbl_orders.pincode}</div>
                </div>
            )
        },
    ]


    return (
        <>
            {
                Loading ? (<Loader />) : ""
            }
            <div className="row">
                <div className="col-12">
                    <h3 className='fw-bold mb-3'>Hold Orders</h3>
                    <div className="row">
                        <div className="col-12">
                            <Box
                                sx={{
                                    height: "82vh",
                                    background: "#fff",
                                    width: '100%',
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#f3f4f6',
                                    },
                                    '& .super-app-theme--header .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 700,
                                    },
                                    '& .super-app-theme': {
                                        paddingTop: "1rem",
                                        // background: "#fff",
                                        maxHeight: "none !important"
                                        // border:"none !important"
                                    },
                                    // '& .MuiDataGrid-virtualScroller':{
                                    //     overflow:"scroll !important"

                                    // }
                                }}
                            >
                                <DataGrid
                                    disableColumnMenu={true}
                                    disableColumnFilter={true}
                                    disableColumnSelector={true}
                                    sx={{
                                        boxShadow: "0px 1px 1px rgb(100 116 139 / 6%), 0px 1px 2px rgb(100 116 139 / 10%)",
                                        '& .MuiDataGrid-row:hover': {
                                            color: 'secondary.main',
                                            outline: "none",
                                            fontWeight: "bold"

                                        }
                                    }} style={{ color: "#000" }} autoHeight={false} columns={Columns} rows={Orders && Orders} getRowId={(x) => x.id} components={{
                                        Pagination: CustomPagination,
                                    }}
                                    getRowClassName={(params) => `super-app-theme`}
                                />

                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
