import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
export default function Cards(props) {
    const { title, count, icon, variant } = props
    return (

        <Card
            sx={{ height: '100%',paddingBottom:"0" }}
            {...props}
            className="card_mi_ui"
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                            className='text-muted fw-bold fs-6'
                        >
                            {title}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                            className='fw-bold'
                        >
                            {count}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: variant.includes("#") ? variant : variant + '.main',
                                height: 56,
                                width: 56
                            }}
                        >
                            {icon}
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
