import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import Loader from '../../Components/Loader';
import ApiHelper from '../../Helper/ApiHelper';

const UpdateComment = (props) => {
    const [Loading, setLoading] = useState(false);
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const { EditcommentData, setEditcommentData, open, setOpen, refetchOrder } = props
    const handleClose = () => {
        setOpen(false)
    }

    const UpdateHendler = async () => {
        try {
            if (!EditcommentData.disposition_desc) {
                return showSnackbar("Require Field Comment Is Empty", { variant: "error" })
            }
            setLoading(false)
            const reuslt = await ApiHelper.UpdateComment(EditcommentData)
            setLoading(false)
            if (reuslt.status === 200) {
                showSnackbar("Comment Updated Successfully", {variant:"success"})
                refetchOrder()
                setOpen(false)
                return
            }
        } catch (err) {
            console.log(err)
            setLoading(false)
            showSnackbar("Somthing Went Wrong With Upadating Commnet", {variant:"error"})
        }
    }
    return (
        <div>
            {
                Loading ? <Loader /> : ""
            }
            <Dialog
                fullWidth={true}
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Update Comment"}
                </DialogTitle>
                <hr className='my-0' />
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <input type="text" value={EditcommentData.disposition_desc} onChange={(e) => setEditcommentData({ ...EditcommentData, disposition_desc: e.target.value })} />
                        </div>
                    </div>
                </DialogContent>
                <hr className='mt-0 mb-2' />
                <DialogActions className='justify-content-between orderDetailsActions px-4 pb-3'>
                    <Button color='error' className='w-50' variant='contained' onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' className='w-50' variant='contained' onClick={UpdateHendler} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UpdateComment;
