import React from 'react'
import { Logo } from '../Layout/logo'

export default function Restricted() {
    if (localStorage.token) {
        window.location.pathname = "/"
    }
    return (
        <div style={{ position: "fixed", width: "100%", height: "100vh", top: 0, left: "0px", backgroundColor: "#fff", zIndex: "100000", display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: "column" }}>
            <Logo />
            <h4 className='mt-3'>
                Restricted IP Address
            </h4>
            <h5 className='text-danger text-center'>
                Errro : <span className='text-dark text-center'> Access from your IP Address Been blocked for security reasons.</span>
            </h5>
        </div>
    )
}
