import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import ApiHelper from '../../Helper/ApiHelper';
import Loader from '../../Components/Loader';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#000",
        color: '#FFF',
        boxShadow: theme.shadows[1],
        fontSize: "1rem",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color:"#000",
      },
}));

const OrderReport = () => {
    const { enqueueSnackbar: showSnackbar } = useSnackbar();
    const [Loading, setLoading] = useState(false);
    const [Rows, setRows] = useState([]);
    const [Total, setTotal] = useState(0);
    const [Dates, setDates] = useState({
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
    });

    const FetchReport = async (from, to) => {
        setDates({
            from: from,
            to: to
        })
        try {
            if (from && to) {
                setLoading(true)
                if (moment(to).isSameOrAfter(from)) {
                    const result = await ApiHelper.FetchOrderRport(from, to)
                    setLoading(false)
                    if (result.status === 200) {
                        let total = 0
                        let i = 0
                        if (result.data && result.data.data && result.data.data.length > 0) {
                            while (i < result.data.data.length) {
                                total += Number(result.data.data[i].total_orders) 
                                i++
                            }
                            setTotal(total)
                            return setRows(result.data.data)
                        }
                        setTotal(0)
                        return setRows([])
                    }
                    setLoading(false)
                    return
                } else {
                    setLoading(false)
                    return
                }
            }
        } catch (err) {
            return showSnackbar("Internal server error", { variant: "error" })
        }
    }

    useEffect(() => {
        FetchReport(Dates.from, Dates.to)
        // eslint-disable-next-line
    }, [])

    const columns = [
        {
            field: "Website", headerName: "Website", flex: 1, headerAlign: "left", renderCell: (cell) => {
                return <>
                    <LightTooltip title={cell.row.source} arrow>
                        <span style={{ cursor: "pointer" }}>{cell.row.website}</span>
                    </LightTooltip>
                </>
            }
        },
        {
            field: "total_orders", headerName: "Orders", flex: 1, headerAlign: "left"
        },
    ]



    return (
        <div>
            {Loading ? <Loader/> : ""}
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-between flex-wrap align-items-center" style={{ gap: "1rem" }}>
                        <h3 className='fw-bold mb-3'>Order Report</h3>
                        <div className='d-flex' style={{ gap: "1rem" }}>
                            <div>
                                <label htmlFor="from">From</label>
                                <input onChange={(e) => FetchReport(e.target.value, Dates.to)} defaultValue={moment().format("YYYY-MM-DD")} type="date" id='from' />
                            </div>
                            <div>
                                <label htmlFor="to">to</label>
                                <input onChange={(e) => FetchReport(Dates.from, e.target.value)} defaultValue={moment().format("YYYY-MM-DD")} type="date" id='to' />
                            </div>
                        </div>
                        <h3 className='fw-bold mb-3'>Total Orders: {Total}</h3>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <DataGrid
                        disableColumnMenu={true}
                        disableColumnFilter={true}
                        disableColumnSelector={true}
                        sx={{
                            boxShadow: "0px 1px 1px rgb(100 116 139 / 6%), 0px 1px 2px rgb(100 116 139 / 10%)",
                            '& .MuiDataGrid-row:hover': {
                                color: 'secondary.main',
                                outline: "none",
                                fontWeight: "bold"
                            }
                        }} style={{ color: "#000" }} pageSize={10} getRowId={e => e.source}  autoHeight={true} columns={columns} rows={Rows && Rows.length > 0 ? Rows : []} 
                        getRowClassName={(params) => `super-app-theme`}
                    />
                </div>
            </div>
        </div>
    );
}

export default OrderReport;
