import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from "notistack"
import ApiHelper from '../../Helper/ApiHelper';
import Loader from '../../Components/Loader';

export default function AddUser(props) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [Loading, setLoading] = useState(false);
    const { open, refetchUser, setOpen } = props
    const [UserInfo, setUserInfo] = useState({
        name: "",
        username: "",
        password: "",
        permissions: "/"
    })


    const handleClose = () => {
        setOpen(false);
    };

    const SubmitHendler = async () => {
        if (UserInfo.username === "" || !UserInfo.username) {
            return showSnackbar("Required Field Username Is Empty", { variant: "error" })
        }
        if (UserInfo.name === "" || !UserInfo.name) {
            return showSnackbar("Required Field Name Is Empty", { variant: "error" })
        }
        if (UserInfo.password === "" || !UserInfo.password) {
            return showSnackbar("Required Field Password Is Empty", { variant: "error" })
        }
        try {
            setLoading(true)
            await ApiHelper.AddUser(UserInfo)
            setLoading(false)
            refetchUser()
            showSnackbar("User Created Successfully", { variant: "success" })
            setOpen(false)
        } catch (err) {
            showSnackbar("Failed To Create User", { variant: "error" })
        }
    }

    return (
        <div>
            {
                Loading ? <Loader /> : ""
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add New User"}
                </DialogTitle>
                <hr className='my-0' />
                <DialogContent>
                    <Typography variant='subtitle1' className='text-muted fw-bold'>Name</Typography>
                    <input type="text" placeholder='Enter Name' onChange={(e) => setUserInfo({ ...UserInfo, name: e.target.value })} />
                    <Typography variant='subtitle1' className='text-muted fw-bold mt-2'>Username</Typography>
                    <input type="text" placeholder='Enter Username' onChange={(e) => setUserInfo({ ...UserInfo, username: e.target.value })} />
                    <Typography variant='subtitle1' className='text-muted fw-bold mt-2'>Password</Typography>
                    <input type="password" placeholder='Enter Password' onChange={(e) => setUserInfo({ ...UserInfo, password: e.target.value })} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">cancel</Button>
                    <Button onClick={SubmitHendler} color="secondary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
