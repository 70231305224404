import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { Button, IconButton } from '@mui/material';
import ApiHelper from '../../Helper/ApiHelper';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditUser from './EditUser';
import Loader from "../../Components/Loader"
import AddUser from './AddUser';
import jwtDecode from "jwt-decode"

export default function Users(props) {
    const [EditOpen, setEditOpen] = useState(false);
    const [AddOpen, setAddOpen] = useState(false);
    const [Data, setData] = useState({});
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [Loading, setLoading] = useState(false);
    const [Rows, setRows] = useState([]);

    const GetCurrentUser = () => {
        try {
            if (localStorage.token) {
                return jwtDecode(localStorage.token || "undefined")
            }
            return undefined
        } catch (err) {
            if (err.message === "Invalid token specified: undefined") {
                localStorage.removeItem("token")
                window.location.pathname = "/signin"
                return undefined
            }
        }
    }


    let UserInfo = GetCurrentUser() || {}
    let Permissions = UserInfo.permissions ? UserInfo.permissions.split(",") : ["/signin"]
    let actions = Permissions.filter((x) => x.includes("=>"))
    actions = actions || []



    const Columns = [
        {
            field: "name", headerName: "Name", flex: 1, headerClassName: "super-app-theme--header"
        },
        {
            field: "username", headerName: "Username", flex: 1, headerClassName: "super-app-theme--header"
        },
        {
            field: "created_at", headerName: "Created Date", flex: 1, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return moment(cell.value).format("ddd MMM YYYY")
            }
        },
        {
            field: "actions", headerName: "Actions", flex: 1, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return (
                    <>
                        {
                            actions.includes("update-user=>") ? (
                                <IconButton disabled={!UserInfo.is_admin} color='secondary' onClick={() => {
                                    setEditOpen(true)
                                    setData({ ...cell.row })
                                }}>
                                    <EditIcon />
                                </IconButton>
                            ) : ""
                        }
                        {
                            actions.includes("remove-user=>") ? (
                                <IconButton disabled={!UserInfo.is_admin} color='error' onClick={async () => {
                                    try {
                                        const confirm = window.confirm("Are Sure To Remove This User")
                                        if (confirm) {
                                            setLoading(true)
                                            await ApiHelper.RemoveUser(cell.row.id)
                                            setLoading(false)
                                            ListUsers()
                                            showSnackbar("User Removed Successfully", { variant: "success" })
                                        }
                                    } catch (err) {
                                        showSnackbar("Failed To Removing User", { variant: "error" })
                                    }
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            ) : ""
                        }
                    </>
                )
            }
        },
    ]

    const ListUsers = async () => {
        try {
            setLoading(false)
            const users = await ApiHelper.ListUser()
            setRows(users.data.Users)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            showSnackbar("Fialed To Fetch Users", { variant: 'error' })
        }
    }

    useEffect(() => {
        ListUsers()
        // eslint-disable-next-line
    }, []);



    return (
        <>
            {
                Loading ? (<Loader />) : ""
            }
            <EditUser refetchUser={ListUsers} PagesName={props.PagesName} Data={Data} setData={setData} open={EditOpen} setOpen={setEditOpen} />
            <AddUser refetchUser={ListUsers} open={AddOpen} setOpen={setAddOpen} />
            <div className="row">
                <div className="col-12">
                    <div className="d-flex orderDetailsActions justify-content-between align-items-center">
                        <h3 className='fw-bold mb-3'>Manege Users</h3>
                        {
                            actions.includes("add-user=>") ? (
                                <Button disabled={!UserInfo.is_admin} variant='contained' onClick={() => setAddOpen(true)} color="secondary">Add New User</Button>
                            ) : ""
                        }
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <Box
                                sx={{
                                    height: "75vh",
                                    background: "#fff",
                                    width: '100%',
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#f3f4f6',
                                    },
                                    '& .super-app-theme--header .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 700,
                                    },
                                    '& .super-app-theme': {
                                        paddingTop: "1rem",
                                        // background: "#fff",
                                        maxHeight: "none !important"
                                        // border:"none !important"
                                    },
                                    // '& .MuiDataGrid-virtualScroller':{
                                    //     overflow:"scroll !important"

                                    // }
                                }}
                            >
                                <DataGrid sx={{
                                    boxShadow: "0px 1px 1px rgb(100 116 139 / 6%), 0px 1px 2px rgb(100 116 139 / 10%)",
                                    '& .MuiDataGrid-row:hover': {
                                        color: 'secondary.main',
                                        outline: "none",
                                        fontWeight: "bold"
                                    }
                                }} style={{ color: "#000" }} autoHeight={false} columns={Columns} pageSize={10} rows={Rows} getRowId={(x) => x.id}
                                    getRowClassName={(params) => `super-app-theme`}
                                />

                            </Box>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
