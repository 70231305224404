import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Loader from '../../Components/Loader';
import ApiHelper from '../../Helper/ApiHelper';

export default function UpdatePayment(props) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const { open, setOpen, refetchOrder, PaymentDetails, setPaymentDetails } = props
    const [Loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };


    const UpdateHendler = async () => {
        const data = { ...PaymentDetails }
        try {
            setLoading(true)
            await ApiHelper.UpdatePaymentMode(data)
            setLoading(false)
            showSnackbar("Payment Details Updated Successfully", { variant: "success" })
            refetchOrder()
            setOpen(false)
        } catch (err) {
            setLoading(false)
            showSnackbar("Failed To Update Payment Details", { variant: "error" })
        }
    }



    return (
        <div>
            {
                Loading ? <Loader /> : ""
            }
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Update Payment Information"}
                </DialogTitle>
                <hr className='my-0' />
                <DialogContent>
                    <Typography className='text-muted fw-bold mt-2'>Payment Mode</Typography>
                    <select value={PaymentDetails.payment_mode} onChange={(e) => setPaymentDetails({ ...PaymentDetails, payment_mode: e.target.value })}>
                        <option value={"online"}>{"online".toUpperCase()}</option>
                        <option value={"cod"}>{"cod".toUpperCase()}</option>
                    </select>
                    <Typography className='text-muted fw-bold mt-2'>Payment Status</Typography>
                    <select value={PaymentDetails.payment_status} onChange={(e) => setPaymentDetails({ ...PaymentDetails, payment_status: e.target.value })}>
                        <option value={"verify"}>{"verify".toUpperCase()}</option>
                        <option value={"reject"}>{"reject".toUpperCase()}</option>
                        <option disabled={PaymentDetails.payment_mode === "online"} value={"pending"}>{"pending".toUpperCase()}</option>
                    </select>
                </DialogContent>
                <hr className='mt-0 mb-2'/>
                <DialogActions className='justify-content-between orderDetailsActions px-4 pb-3'>
                    <Button color='error' className='w-50' variant='contained' onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' className='w-50' variant='contained' onClick={UpdateHendler} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
