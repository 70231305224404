import { Button, Chip } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ApiHelper from '../../Helper/ApiHelper'

export default function SearchOrder() {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const [FilterType, setFilterType] = useState("order_id")
    const [Search, setSearch] = useState("");
    const [rows, setrows] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [Products, setProducts] = useState([]);

    const GetData = async () => {
        try {
            let data = {}
            if (FilterType === "order_id") {
                data = {
                    order_id: Search,
                    filter: "order_id"
                }
            } else if (FilterType === "tracking_id") {
                data = {
                    tracking_id: Search,
                    filter: "tracking_id"
                }
            } else if (FilterType === "crmid") {
                data = {
                    crmid: Search,
                    filter: "crmid"
                }
            } else if (FilterType === "phone") {
                data = {
                    phone: Search,
                    filter: "phone"
                }
            }
            setLoading(true)
            const result = await ApiHelper.SearchByIds(data)
            setLoading(false)
            if (result.status === 200) {
                return setrows(result.data.data)
            }
        } catch (err) {
            setLoading(false)
            setrows([])
            showSnackbar("Failed To Fetch Orders", { variant: "error" })
        }
    }

    const FetchProduct = async () => {
        try {
            setLoading(true)
            const Product = await ApiHelper.FetchProdcuts()
            setLoading(false)
            setProducts(Product.data.Products)
        } catch (err) {
            setLoading(false)
            setProducts([])
            showSnackbar("Failed To Fetch Products", { variant: "error" })
        }
    }

    useEffect(() => {
        FetchProduct()
        // eslint-disable-next-line 
    }, []);

    const Columns = [
        {
            field: "created_at", sortable: false, headerName: "ORDER DATE", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return <>
                    <div className='d-flex flex-column w-100'>
                        <span>{moment(cell.value).format("DD-MMM-YY")}</span>
                        {/* <span>{moment(cell.value).fromNow()}</span> */}
                    </div>
                </>
            }
        },
        {
            field: "order_id", sortable: false, headerName: "ORDER INFO", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return (<>
                    <div className="d-flex flex-column">
                        <div>
                            <Link className='text-danger' onClick={() => localStorage.lastUrl = window.location.href}  style={{ textDecoration: "underline" }} to={`/order/${cell.value}${!cell.row.crmid ? "?new-order=true" : ""}`}>
                                {cell.value}
                            </Link>
                        </div>
                        {/* <div>
                            {cell.row.customer_name}
                        </div> */}
                    </div>
                </>)
            }
        },
        { field: "customer_Name", sortable: false, headerName: "CUSTOMER NAME", width: 200, headerClassName: "super-app-theme--header", renderCell:(cell) => cell.row.customer_name },
        {
            field: "crmid", sortable: false, headerName: "CRM ID", width: 150, headerClassName: "super-app-theme--header", renderCell: (cell) => (
                <>
                    <span className='fw-bold'>
                        {
                            cell.value ? cell.value : "N/A"
                        }
                    </span>
                </>
            )
        },
        {
            field: "tracking_id", sortable: false, headerName: "TRACKING ID", width: 150, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return <>
                    <span>
                        {
                            cell.value ? cell.value : "N/A"
                        }
                    </span>
                </>
            }
        },
        {
            field: "status", sortable: false, headerName: "ORDER STATUS", width: 150, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return <>
                    <Chip style={{ width: "200px" }} color={cell.value === "verify" ? "info" : cell.value === "dispatch" && cell.row.location === 'returned' ? "error" : cell.value === "dispatch" ? "success" : cell.value === "cancel" ? "error" : cell.value === "hold" ? "warning" : "warning"} label={cell.value ? cell.value === "dispatch" && cell.row.location !== "in_transit" ? cell.row.location.toUpperCase() : cell.value === "verify" ? "ACCEPTED" : cell.value.toUpperCase() || "PENDING" : "PENDING"} />

                </>
            }
        },
        {
            field: "payment_status", sortable: false, headerName: "PAYMENT STATUS", width: 150, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return (<>
                    <Chip style={{ width: "200px" }} color={cell.value === "pending" ? "warning" : cell.value === "reject" ? "error" : "success"} label={cell.value ? cell.value.toUpperCase() : "N/A"} />
                </>)
            }
        },
        {
            field: "product", sortable: false, headerName: "PRODUCT", width: 200, headerClassName: "super-app-theme--header", renderCell: (cell) => {
                return <>
                    <span className='fs-6'>{Products.find((x) => x.id === cell.value).name.substring(0, 19)}</span>
                </>
            }
        },
        {
            field: "total_price", sortable: false, headerName: "ORDER TOTAL", width: 150, headerClassName: "super-app-theme--header", renderCell: (cell) => (
                <>
                    <Chip style={{ width: "200px" }} color="info" label={`${cell.value} - ${cell.row.payment_mode}`} />
                </>
            )
        },
        { field: "customer_phone", sortable: false, headerName: "CONTACT", width: 200, headerClassName: "super-app-theme--header" },
        {
            field: "state", headerName: "DESTINATION", flex: 1, headerClassName: "super-app-theme--header", renderCell: (cell) => (
                <div className="d-flex flex-column">
                    <div>{cell.value}</div>
                    <div>Pin: {cell.row.pincode}</div>
                </div>
            )
        },
    ]



    return (
        <>

            <div className="row">
                <div className="col-12">
                    <h3 className='fw-bold mb-3'>Search Orders</h3>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mb-2">
                            <input onChange={(e) => setSearch(e.target.value)} type="text" placeholder='Search here with Order Id / CRM Id / Tracking Id' />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-2">
                            <select value={FilterType} onChange={(e) => setFilterType(e.target.value)}>
                                <option value="order_id">Order Id</option>
                                <option value="crmid">CRM Id</option>
                                <option value="tracking_id">Tracking Id</option>
                                <option value="phone">Phone Number</option>
                            </select>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <Button onClick={GetData} style={{ paddingTop: "1rem", paddingBottom: "1rem" }} color="secondary" fullWidth variant='outlined'>Search</Button>
                        </div>
                        <div className="col-12 mt-5">
                            <Box
                                sx={{
                                    height: "82vh",
                                    background: "#fff",
                                    width: '100%',
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#f3f4f6',
                                    },
                                    '& .super-app-theme--header .css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 700,
                                    },
                                    '& .super-app-theme': {
                                        paddingTop: "1rem",
                                        // background: "#fff",
                                        maxHeight: "none !important"
                                        // border:"none !important"
                                    },
                                    // '& .MuiDataGrid-virtualScroller':{
                                    //     overflow:"scroll !important"

                                    // }
                                }}
                            >
                                <DataGrid
                                    loading={Loading}
                                    disableColumnMenu={true}
                                    disableColumnFilter={true}
                                    disableColumnSelector={true}
                                    sx={{
                                        boxShadow: "0px 1px 1px rgb(100 116 139 / 6%), 0px 1px 2px rgb(100 116 139 / 10%)",
                                        '& .MuiDataGrid-row:hover': {
                                            color: 'secondary.main',
                                            outline: "none",
                                            fontWeight: "bold"
                                        }
                                    }} style={{ color: "#000" }} columns={Columns} autoPageSize={true} rows={rows || []} getRowId={(x) => x.order_id}
                                    getRowClassName={(params) => `super-app-theme`}
                                />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
