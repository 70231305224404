import React from 'react'

export default function Loader() {
    return (
        <div className="d-flex align-items-center justify-content-center" style={{ position:"fixed", height:"100vh",width:"100vw", top:"0",left:"0", zIndex:"1000000",background:"#ffffff5e" }}>
            <div className="text-center">
                <div className="spinner-border" style={{ width:"5rem",height:"5rem",borderColor:'#5048e5', borderRightColor:"transparent" }}  role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}
