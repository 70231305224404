import jwtDecode from 'jwt-decode'
import React from 'react'

export default function Home(props) {
  let user;
  if (localStorage.token) {
    user = jwtDecode(localStorage.token)
    console.log(user)
  }
  if(user && user.permissions.length > 0){
    if(user.permissions.split(",")[0] !== "/"){
      window.location.pathname = user.permissions.split(",")[0]
    }else{
      if(user.permissions.split(",")[1]){
        window.location.pathname = user.permissions.split(",")[1]
      }else{
        localStorage.removeItem('token')
        return window.location.pathname = "/signin"
      }
    }
  }else{
    localStorage.removeItem("token")
    return window.location.pathname = "/signin"
  }
  return (
    <div className='w-100' style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>Choose from menu to see content here.</div>
  )
}
