import axios from "axios"
// import {useNavigate} from "react-router-dom"

class ApiHelper {
    constructor() {
        // this.url = "http://order-room.brahmikalp.com/api/"
        // this.url = "http://192.168.1.6:9560/api/"
        // this.url = "http://192.168.88.6:9560/api/"
        this.url = process.env.REACT_APP_API_URL
        this.token = localStorage.token ? localStorage.token : "undefined"
        this.token = this.token === "" ? "undefined" : this.token
        axios.interceptors.response.use((res) => {
            return res
        }, (err) => {
            if(err.response.status === 503){
                localStorage.removeItem("token")
                if(window.location.pathname !== "/restricted"){
                    return window.location.pathname = "/restricted"
                }
            }
            if (err.response.status === 400) {
                localStorage.removeItem('token')
                if (window.location.pathname !== "/signin") {
                    localStorage.removeItem("token")
                    window.location.pathname = "/signin"
                }
            }
            return Promise.reject(err)
        })
    }

    FetchOrderRport = async(from,to) => {
        return await axios.get(`${this.url}order/report?from=${from}&to=${to}`,{headers:{"auth":`${this.token}`}})
    }

    UpdateComment = async(data) => {
        return axios.put(`${this.url}order/update-comment`,data, {headers:{"auth":`${this.token}`}})
    }

    GetDes = async(from,to) => {
        return axios.get(`${this.url}get-total?from=${from}&to=${to}`, {headers:{"auth": `${this.token}`}})
    }

    FeckRequest = async() => {
        return await axios.get(this.url)
    }
    FetchTodayDetails = async () => {
        return await axios.get(`${this.url}order/total/today`, { headers: { "auth": `${this.token}` } })
    }
    FetchMothlyDetails = async () => {
        return await axios.get(`${this.url}total-orders/month`, { headers: { "auth": `${this.token}` } })
    }
    GetChartData = async () => {
        return await axios.get(`${this.url}order/chart`, { headers: { "auth": `${this.token}` } })
    }
    RemoveUser = async (id) => {
        return await axios.delete(`${this.url}user/delete?id=${id}`, { headers: { "auth": `${this.token}` } })
    }
    AddUser = async (data) => {
        return await axios.post(`${this.url}user/create`, data, { headers: { "auth": `${this.token}` } })
    }
    UpdateUser = async (data) => {
        return await axios.put(`${this.url}user/update`, data, { headers: { "auth": `${this.token}` } })
    }
    ListUser = async () => {
        return await axios.get(`${this.url}user/list`, { headers: { 'auth': `${this.token}` } })
    }
    Sigin = async (data) => {
        return await axios.post(`${this.url}user/signin`, data)
    }
    FetchCourierServices = async () => {
        return await axios.get(`${this.url}fetch-courier-partners`, { headers: { "auth": `${this.token}` } })
    }
    Updatedisposition = async (data) => {
        return await axios.put(`${this.url}update/status`, data, { headers: { "auth": `${this.token}` } })
    }
    UpdateTransitStatus = async (data) => {
        return await axios.put(`${this.url}update/transitstatus`, data, { headers: { "auth": `${this.token}` } })
    }
    FetchNewOrder = async (from, to) => {
        return await axios.get(`${this.url}orders/new?from=${from}&to=${to}`, { headers: { "auth": `${this.token}` } })
    }
    FetchOrderByStatus = async (from, to, status) => {
        return await axios.get(`${this.url}orders/fetchbystatus/${status}?from=${from}&to=${to}`, { headers: { "auth": `${this.token}` } })
    }
    FetchProdcuts = async () => {
        return await axios.get(`${this.url}product/fetch-all`, { headers: { "auth": `${this.token}` } })
    }
    FetchOrderByLocation = async (from, to, location) => {
        return await axios.get(`${this.url}orders/fetch-by-location/${location}?from=${from}&to=${to}`, { headers: { "auth": `${this.token}` } })
    }
    FetchSingelOrderDetails = async (orderid) => {
        return await axios.get(`${this.url}orders/${orderid}`, { headers: { "auth": `${this.token}` } })
    }
    InsertOrderRoom = async (data) => {
        return await axios.post(`${this.url}orders/order-room/add`, data, { headers: { "auth": `${this.token}` } })
    }
    UpdateUserPassword = async (data) => {
        return await axios.put(`${this.url}user/update-password`, data, { headers: { "auth": `${this.token}` } })
    }
    UpdateProductDetails = async (data) => {
        return await axios.put(`${this.url}update/product`, data, { headers: { "auth": `${this.token}` } })
    }
    UpdatePaymentMode = async (data) => {
        return await axios.put(`${this.url}update/payment`, data, { headers: { "auth": `${this.token}` } })
    }
    GetPincodes = async (pincode) => {
        return axios.get("https://api.postalpincode.in/pincode/" + pincode)
    }
    UpdateCustomerDetails = async (data) => {
        return await axios.put(`${this.url}update/customer`, data, { headers: { "auth": `${this.token}` } })
    }
    SearchByIds = async (data) => {
        return await axios.post(`${this.url}order/filter`,data, {headers:{"auth":`${this.token}`}})
    }
}

export default new ApiHelper()