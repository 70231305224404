import { Switch, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import ApiHelper from '../../Helper/ApiHelper';
import { useSnackbar } from "notistack"
import Loader from '../../Components/Loader';

export default function EditUser(props) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const { open, setOpen, refetchUser, Data, setData, PagesName } = props
    const [Loading, setLoading] = useState(false);
    const [Type, setType] = useState(undefined);
    const [Permissions, setPermissions] = useState([]);
    const [PasswordData, setPasswordData] = useState({
        OldPassword: "",
        NewPassword: ""
    });

    const handleClose = () => {
        setData({})
        setType(undefined)
        setOpen(false);
    };

    const UpdateHelder = async () => {
        if (Type === "" || !Type) {
            return showSnackbar("Please Choose Updateble Field", { variant: "info" })
        }
        if (!Data.username || Data.username === "") {
            return showSnackbar("Reuried Field Username Is Empty", { variant: "error" })
        }
        if (!Data.name || Data.name === "") {
            return showSnackbar("Reuried Field Name Is Empty", { variant: "error" })
        }

        if (Type === "permissions") {
            Data.permissions = Permissions.join(",")
        }
        delete Data.created_at
        delete Data.updated_at
        delete Data.password
        let data = { ...Data }
        try {
            setLoading(true)
            await ApiHelper.UpdateUser(data)
            setLoading(false)
            refetchUser()
            setOpen(false)
            showSnackbar("User Updated Successfully", { variant: "success" })
        } catch (err) {
            setLoading(false)
            showSnackbar("Failed To Update User", { variant: 'error' })
        }
    }


    const UpdatePassword = async () => {
        try {
            const data = { ...PasswordData, id: Data.id, Oldencrypted: Data.password }
            if (!data.OldPassword || data.OldPassword === "") {
                return showSnackbar("Reuired Field Old Password Is Empty", { variant: 'error' })
            }
            if (!data.NewPassword || data.NewPassword === "") {
                return showSnackbar("Reuired Field New Password Is Empty", { variant: 'error' })
            }
            setLoading(true)
            await ApiHelper.UpdateUserPassword(data)
            setLoading(false)
            setOpen(false)
            showSnackbar("Password Updated Successfully", { variant: "success" })
        } catch (err) {
            console.log(err)
            setLoading(false)
            showSnackbar("Failed To Update Password", {variant:"error"})
        }
    }


    return (
        <div>
            {
                Loading ? <Loader /> : ""
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Edit User"}
                </DialogTitle>
                <hr className='my-0' />
                <DialogContent>
                    <Typography variant='subtitle1' className='text-muted fw-bold'>Select Field</Typography>
                    <select value={Type} onChange={(e) => {
                        setType(e.target.value)
                        if (e.target.value === 'permissions') {
                            setPermissions(Data.permissions ? Data.permissions.split(",") : ["/"])
                        }
                    }}>
                        <option value={undefined}>Choose Editable Field</option>
                        <option value={"username"}>Username</option>
                        <option value={"name"}>Name</option>
                        <option value={"password"}>Password</option>
                        <option value={"permissions"}>Permissions</option>
                        <option value={"is_admin"}>Is Admin</option>
                    </select>

                    {
                        Type === "password" ? (
                            <>
                                <Typography variant='subtitle1' className='text-muted fw-bold mt-2'>Old Password</Typography>
                                <input onChange={(e) => setPasswordData({ ...PasswordData, OldPassword: e.target.value })} type="password" placeholder='Enter Old Password' />
                                <Typography variant='subtitle1' className='text-muted fw-bold mt-2'>New Password</Typography>
                                <input onChange={(e) => setPasswordData({ ...PasswordData, NewPassword: e.target.value })} type="password" placeholder='Enter New Password' />
                            </>
                        ) : ""
                    }
                    {
                        Type === "username" ? (
                            <>
                                <Typography variant='subtitle1' className='text-muted fw-bold mt-2'>Username</Typography>
                                <input type="text" onChange={(e) => setData({ ...Data, username: e.target.value })} value={Data.username} placeholder='Enter New Username' />
                            </>
                        ) : ""
                    }
                    {
                        Type === "name" ? (
                            <>
                                <Typography variant='subtitle1' className='text-muted fw-bold mt-2'>Name</Typography>
                                <input type="text" onChange={(e) => setData({ ...Data, name: e.target.value })} value={Data.name} placeholder='Enter New Name' />
                            </>
                        ) : ""
                    }
                    {
                        Type === "is_admin" ? (
                            <>
                                <div className="d-flex align-items-center justify-content-start">
                                    <Switch onChange={(e) => setData({ ...Data, is_admin: e.target.checked })} id='is_admin' color='secondary' className='mt-2' checked={Data.is_admin} />
                                    <label className='fw-bold mt-2' htmlFor="is_admin">{Data.is_admin ? "Yes" : "No"}</label>
                                </div>
                            </>
                        ) : ""
                    }
                    {
                        Type === "permissions" ? Object.keys(PagesName).map((x, key) => (
                            <div className='text-bold mt-2'>
                                <Switch color='secondary' id={`perm${key}`} checked={Permissions.includes(x)} onChange={() => {
                                    if (!Permissions.includes(x)) {
                                        setPermissions([...Permissions, x])
                                    } else {
                                        let index = Permissions.findIndex((e) => e === x)
                                        Permissions.splice(index, 1)
                                        setPermissions([...Permissions])
                                    }
                                }} />
                                <label className='fw-bold' htmlFor={`perm${key}`}>{PagesName[x]}</label>
                            </div>
                        )) : ""

                    }

                </DialogContent>
                <DialogActions className='justify-content-end'>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' onClick={Type === "password" ? UpdatePassword : UpdateHelder} >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
