import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Loader from '../../Components/Loader';
import ApiHelper from '../../Helper/ApiHelper';

export default function UpdateCustomer(props) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const { open, setOpen, refetchOrder, CustomerDetails, setCustomerDetails } = props
    const [Loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };


    const UpdateHendler = async () => {
        const data = { ...CustomerDetails }
        if (!data.customer_name || data.customer_name === "" || data.customer_name === "undefined") {
            return showSnackbar("Required Field Customer Name Is Empty", { variant: "error" })
        }
        if (!data.customer_phone || data.customer_phone === "" || data.customer_phone === "undefined") {
            return showSnackbar("Required Field Customer Phone Is Empty", { variant: "error" })
        }
        if (!data.customer_address || data.customer_address === "" || data.customer_address === "undefined") {
            return showSnackbar("Required Field Customer Address Is Empty", { variant: "error" })
        }
        if (!data.pincode || data.pincode === "" || data.pincode === "undefined") {
            return showSnackbar("Required Field Pincode Is Empty", { variant: "error" })
        }

        try {
            setLoading(true)
            data.order_id = Number(data.order_id)
            await ApiHelper.UpdateCustomerDetails(data)
            setLoading(false)
            showSnackbar("Customer Details Updated Successfully", { variant: "success" })
            refetchOrder()
            setOpen(false)
        } catch (err) {
            setLoading(false)
            showSnackbar("Failed To Update Customer Details", { variant: "error" })
        }
    }

    const GetPincode = async (e) => {
        setCustomerDetails({ ...CustomerDetails, pincode: e.target.value })
        if (e.target.value.length === 6) {
            try {
                setLoading(true)
                const result = await ApiHelper.GetPincodes(e.target.value)
                if (result.data[0].PostOffice) {
                    CustomerDetails.city = result.data[0].PostOffice[0].District
                    CustomerDetails.state = result.data[0].PostOffice[0].State
                    setCustomerDetails({...CustomerDetails})
                }
                setLoading(false)
            } catch (err) {
                setLoading(false)
                showSnackbar("Invalide Pincode", { variant: "error" })
            }
        }
        setCustomerDetails({ ...CustomerDetails, pincode: e.target.value })
    }



    return (
        <div>
            {
                Loading ? <Loader /> : ""
            }
            <Dialog
                fullWidth={true}
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Update Customer Details"}
                </DialogTitle>
                <hr className='my-0' />
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Typography className='text-muted fw-bold'>Customer Name</Typography>
                            <input type="text" value={CustomerDetails.customer_name}  onChange={(e) => setCustomerDetails({...CustomerDetails,customer_name:e.target.value})}/>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Typography className='text-muted fw-bold'>Customer Phone <span style={{ fontSize: "0.7rem" }} className=' text-danger fw-lighter'>(READ ONLY)</span></Typography>
                            <input type="text" disabled value={CustomerDetails.customer_phone} onChange={(e) => setCustomerDetails({...CustomerDetails,customer_phone:e.target.value})} />
                        </div>
                        <div className="col-12 mt-3">
                            <Typography className='text-muted fw-bold'>Customer Address</Typography>
                            <input type="text" className='py-5' value={CustomerDetails.customer_address} onChange={(e) => setCustomerDetails({...CustomerDetails,customer_address:e.target.value})} />
                        </div>
                        <div className="col-md-4 col-sm-12 mt-3">
                            <Typography className='text-muted fw-bold'>Pincode</Typography>
                            <input type="text" maxLength={6} value={CustomerDetails.pincode} onChange={GetPincode} />
                        </div>
                        <div className="col-md-4 col-sm-12 mt-3">
                            <Typography className='text-muted fw-bold'>City <span style={{ fontSize: "0.7rem" }} className=' text-danger fw-lighter'>(READ ONLY)</span></Typography>
                            <input type="text" readOnly disabled={true} value={CustomerDetails.city} />
                        </div>
                        <div className="col-md-4 col-sm-12 mt-3">
                            <Typography className='text-muted fw-bold'>State <span style={{ fontSize: "0.7rem" }} className=' text-danger fw-lighter'>(READ ONLY)</span></Typography>
                            <input disabled={true} readOnly type="text" value={CustomerDetails.state} />
                        </div>
                    </div>
                </DialogContent>
                <hr className='mt-0 mb-2' />
                <DialogActions className='justify-content-between orderDetailsActions px-4 pb-3'>
                    <Button color='error' className='w-50' variant='contained' onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' className='w-50' variant='contained' onClick={UpdateHendler} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
