import { Button, Card, CardContent, Chip, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ApiHelper from '../../Helper/ApiHelper'
import { useParams } from "react-router-dom"
import { useSnackbar } from "notistack"
import Loader from "../../Components/Loader"
import moment from 'moment'
import UpdateOrder from './UpdateOrder'
import jwtDecode from "jwt-decode"
import UpdateProduct from './UpdateProduct'
import UpdatePayment from './UpdatePayment'
import UpdateCustomer from './UpdateCustomer'
import UpdateComment from './UpdateComment'

export default function Orderdetails(props) {
  const [EditcommentData, setEditcommentData] = useState({
    order_id: undefined,
    disposition_desc: undefined
  });
  const [CommentOpen, setCommentOpen] = useState(false);
  const [CustomerDetails, setCustomerDetails] = useState({});
  const [CustomerOpen, setCustomerOpen] = useState(false);
  const [PaymnetOpen, setPaymnetOpen] = useState(false);
  const [PaymentDetails, setPaymentDetails] = useState({});
  const [ProductOpen, setProductOpen] = useState(false);
  const [ProductDetails, setProductDetails] = useState({});
  const [OrderDetails, setOrderDetails] = useState({})
  const [UpdatebaleData, setUpdatebaleData] = useState({});
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const { enqueueSnackbar: showSnackbar } = useSnackbar()
  const [Products, setProducts] = useState([]);
  const [Loading, setLoading] = useState(false);
  const OrderId = useParams().orderid
  const GetOrderDetails = async () => {
    try {
      setLoading(true)
      const result = await ApiHelper.FetchSingelOrderDetails(OrderId)
      setLoading(false)
      setOrderDetails(result.data.Order)
    } catch (err) {
      console.log(err);
      setLoading(false)
      setOrderDetails({})
      showSnackbar("Failed To Fetch Order Details.", { variant: "error" })
    }
  }

  const GetUserInfo = () => {
    try {
      if (localStorage.token) {
        return jwtDecode(localStorage.token || "undefined")
      }
      return undefined
    } catch (err) {
      if (err.message === "Invalid token specified: undefined") {
        localStorage.removeItem("token")
        window.location.pathname = "/signin"
        return undefined
      }
    }
  }


  let UserInfo = GetUserInfo() || {}
  let Permissions = UserInfo.permissions ? UserInfo.permissions.split(",") : ["/signin"]
  let actions = Permissions.filter((x) => x.includes("=>"))
  actions = actions || []


  const GetProductDetails = async () => {
    try {
      setLoading(true)
      const Result = await ApiHelper.FetchProdcuts()
      setLoading(false)
      setProducts(Result.data.Products)
    } catch (err) {
      setProducts([])
      setLoading(false)
      showSnackbar("Failed To Fetch Products.", { variant: "error" })
    }
  }



  useEffect(() => {
    GetProductDetails()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetOrderDetails()
    // eslint-disable-next-line
  }, []);

  const GetTime = (date) => {
    if (date) {
      date = date.replace("000Z", "0530")
      return moment(date).format("LT")
    }
  }

  if (OrderDetails.order_id) {
    if (typeof window.location.href.split("?new-order")[1] === "undefined") {
      if (!OrderDetails.status && !OrderDetails.tbl_order_room) {
        return window.location.href = window.location.href + "?new-order=true"
      }
    }
  }

  const isNewOrder = window.location.search.split("?new-order=")[1] === "true" ? true : false


  return (
    <>
      {
        Loading ? <Loader /> : ""
      }
      <UpdateComment open={CommentOpen} EditcommentData={EditcommentData} setEditcommentData={setEditcommentData} setOpen={setCommentOpen} refetchOrder={GetOrderDetails} />
      <UpdateCustomer open={CustomerOpen} setOpen={setCustomerOpen} CustomerDetails={CustomerDetails} setCustomerDetails={setCustomerDetails} refetchOrder={GetOrderDetails} />
      <UpdatePayment refetchOrder={GetOrderDetails} PaymentDetails={PaymentDetails} setPaymentDetails={setPaymentDetails} open={PaymnetOpen} setOpen={setPaymnetOpen} />
      <UpdateProduct refetchOrder={GetOrderDetails} setProductDetails={setProductDetails} ProductDetails={ProductDetails} open={ProductOpen} setOpen={setProductOpen} />
      <UpdateOrder UserInfo={UserInfo} refetchOrder={GetOrderDetails} open={UpdateOpen} setdata={setUpdatebaleData} data={UpdatebaleData} setOpen={setUpdateOpen} />
      <div className="row">
        <div className="col-12">
          <h3 className='fw-bold mb-3'>Order Details</h3>
          <div className="row" style={{ rowGap: "1rem" }}>
            <div className="col-md-12 col-lg-4 col-12">
              <Card sx={{ maxWidth: "100%" }}>
                <CardContent>
                  <h5>Order Information</h5>
                  <hr />
                  <h6 className='mb-2'><span className="fw-bold">Status:</span>
                    {
                      !OrderDetails.location || OrderDetails.location === "in_transit" ? (
                        <Chip style={{ marginLeft: "1rem", width: "130px" }} label={OrderDetails.status ? OrderDetails.status.toUpperCase() : "Pending".toUpperCase()} color={OrderDetails.status === "verify" ? "info" : OrderDetails.status === "dispatch" ? "success" : OrderDetails.status === "hold" ? "warning" : OrderDetails.status === "cancel" ? "error" : "warning"} />
                      ) : (
                        <Chip style={{ marginLeft: "1rem", width: "130px" }} label={OrderDetails.location ? OrderDetails.location.toUpperCase() : "N/A"} color={OrderDetails.location === "delivered" ? "success" : "error"} />
                      )
                    }
                  </h6>
                  <h6 className='mb-2'><span className="fw-bold">Order Id:</span> {OrderId}</h6>
                  <h6 className='mb-2'><span className="fw-bold">CRM Id:</span> {OrderDetails.crmid || "N/A"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">Total Amount:</span> {OrderDetails.total_price || "N/A"}</h6>
                  {/* <h6 className='mb-2'><span className="fw-bold">Ordered At:</span> {OrderDetails.created_at ? moment(OrderDetails.created_at).format("DD/MM/YY") + " " + GetTime(OrderDetails.created_at) : "N/A"}</h6> */}
                  <h6 className='mb-2'><span className="fw-bold">Ordered At:</span> {OrderDetails.created_at ? moment(OrderDetails.created_at).utc().local().format("DD/MM/YY hh:mm A") : "N/A"}</h6>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-12 col-lg-4 col-12">
              <Card sx={{ maxWidth: "100%" }}>
                <CardContent>
                  <div className="d-flex justify-content-between align-items-start">
                    <h5>Product Information</h5>
                    {
                      isNewOrder ? (
                        <Button style={{ borderRadius: "0" }} className='fs-6' color='secondary' onClick={() => {
                          setProductOpen(true)
                          setProductDetails({
                            name: OrderDetails.product ? Products.length ? Products.find((x) => x.id === OrderDetails.product).name : undefined : undefined,
                            qty: OrderDetails.qty,
                            total_price: OrderDetails.total_price,
                            order_id: OrderId,
                          })
                        }} >Edit</Button>
                      ) : ""
                    }
                  </div>
                  <hr className='mt-1' />
                  <h6 className='mb-2'><span className="fw-bold">Name:</span> <Tooltip title={
                    OrderDetails.product ? Products.length ? Products.find((x) => x.id === OrderDetails.product).name : 'N/A' : "N/A"
                  }><span className='text-dark'>{OrderDetails.product ? Products.length ? Products.find((x) => x.id === OrderDetails.product).name.substring(0, 19) : "N/A" : "N/A"}</span></Tooltip></h6>
                  <h6 className='mb-2'><span className="fw-bold">Price:</span> {OrderDetails.total_price || "N/A"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">QTY:</span> {1}</h6>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-12 col-lg-4 col-12">
              <Card sx={{ maxWidth: "100%" }}>
                <CardContent>
                  <div className="d-flex justify-content-between align-items-start">
                    <h5>Payment Information</h5>
                    {
                      isNewOrder ? (
                        <Button style={{ borderRadius: "0" }} className='fs-6' color='secondary' onClick={() => {
                          setPaymnetOpen(true)
                          setPaymentDetails({
                            order_id: OrderId,
                            payment_mode: OrderDetails.payment_mode || undefined,
                            payment_status: OrderDetails.payment_status || undefined
                          })
                        }}>Edit</Button>
                      ) : ""
                    }
                  </div>
                  <hr className='mt-1' />
                  <h6 className='mb-2'><span className="fw-bold">Payment Method:</span> <Chip style={{ marginLeft: "1rem", width: "130px" }} label={OrderDetails.payment_mode ? OrderDetails.payment_mode.toUpperCase() : "N/A"} color="info" /></h6>
                  <h6 className='mb-2'><span className="fw-bold">Payment Status:</span> <Chip style={{ marginLeft: "1rem", width: "130px" }} label={OrderDetails.payment_status ? OrderDetails.payment_status.toUpperCase() : "N/A"} color={OrderDetails.payment_status === "pending" ? "warning" : OrderDetails.payment_status === "verify" ? "success" : OrderDetails.payment_status === "reject" ? "error" : "secondary"} /></h6>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-12 col-lg-4 col-12">
              <Card sx={{ maxWidth: "100%" }}>
                <CardContent>
                  <h5>Order Process & Tracking</h5>
                  <hr />
                  <h6 className='mb-2'><span className="fw-bold text-dark">Last Processing By: </span> {OrderDetails.user_id ? OrderDetails.user_name || "N/A" : "N/A"}</h6>
                  <hr />
                  {/* <h6 className='mb-2'><span className="fw-bold">Comment:</span> {OrderDetails.disposition_desc || "N/A"}</h6> */}
                  <h6 className='mb-2'><span className="fw-bold">Courier Provider:</span> {OrderDetails.corier_partner_alias || "(Not dispatched) - N/A"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">City:</span> {OrderDetails.city || "N/A"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">Dispatched At:</span> {OrderDetails.dispatched_at ? moment(OrderDetails.dispatched_at).format("DD/MM/YY") + " " + GetTime(OrderDetails.dispatched_at) : "Not dispatched"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">Delivered At:</span> {OrderDetails.delivered_at ? moment(OrderDetails.delivered_at).format("DD/MM/YY") + " " + GetTime(OrderDetails.delivered_at) : "Either not delivered, or returned"} </h6>
                  <hr />
                  <small className='fs-6'>Order is yet to be processed by agents.</small>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-12 col-lg-4 col-12">
              <Card sx={{ maxWidth: "100%" }}>
                <CardContent>
                  <div className="d-flex justify-content-between align-items-start">
                    <h5>Ship Information</h5>
                    {
                      isNewOrder ? (
                        <Button style={{ borderRadius: "0" }} className='fs-6' color='secondary' onClick={() => {
                          setCustomerOpen(true)
                          setCustomerDetails({
                            order_id: OrderId,
                            customer_name: OrderDetails.customer_name,
                            customer_address: OrderDetails.customer_address,
                            customer_phone: OrderDetails.customer_phone,
                            pincode: OrderDetails.pincode,
                            city: OrderDetails.city,
                            state: OrderDetails.state
                          })
                        }} >Edit</Button>
                      ) : ""
                    }
                  </div>
                  <hr className='mt-1 mb-2' />
                  <h6 className='mb-2'><span className="fw-bold">Name:</span> {OrderDetails.customer_name || "N/A"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">Phone:</span> {OrderDetails.customer_phone || "N/A"}</h6>
                  <hr />
                  <h6 className='mb-2'><span className="fw-bold">Address:</span> {OrderDetails.customer_address || "N/A"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">City:</span> {OrderDetails.city || "N/A"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">State:</span> {OrderDetails.state || "N/A"}</h6>
                  <h6 className='mb-2'><span className="fw-bold">Pincode:</span> {OrderDetails.pincode || "N/A"}</h6>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-12 col-lg-4 col-12">
              <Card sx={{ maxWidth: "100%" }}>
                <CardContent>
                  <div className="d-flex justify-content-between align-items-start">
                    <h5>Comment</h5>
                    {

                      OrderDetails.status && OrderDetails.status === "hold" ? (
                        <Button style={{ borderRadius: "0" }} className='fs-6' color='secondary' onClick={() => {
                          setCommentOpen(true)
                          setEditcommentData({
                            order_id: OrderId,
                            disposition_desc: OrderDetails.disposition_desc || ""
                          })
                        }} >Edit</Button>
                      ) : ""
                    }
                  </div>
                  <hr className='mt-1 mb-2' />
                  <h6 className='mb-2'><span className="fw-bold">{OrderDetails.disposition_desc || "N/A"}</span></h6>
                </CardContent>
              </Card>
            </div>
          </div>
          {
            !OrderDetails.location || OrderDetails.location === "in_transit" ? (
              <>
                <hr className='mt-5' />
                <div className="d-flex orderDetailsActions justify-content-sm-start align-items-center flex-wrap" style={{ gap: "1rem" }}>
                  {
                    actions.includes("accept-order=>") ? (!OrderDetails.status || OrderDetails.status === "hold") ? (
                      <Button color='info' variant='contained' onClick={() => {
                        setUpdateOpen(true)
                        setUpdatebaleData({
                          status: "verify",
                          title: `Accept Order : ${OrderId}`,
                          disposition_desc: null,
                          order_id: OrderId
                        })
                      }}>Accept Order</Button>
                    ) : "" : ""
                  }
                  {
                    actions.includes("hold-order=>") ? (OrderDetails.tbl_order_room === null && !OrderDetails.status) ? (
                      <Button color='warning' variant='contained' onClick={() => {
                        setUpdateOpen(true)
                        setUpdatebaleData({
                          status: "hold",
                          title: `Hold Order : ${OrderId}`,
                          order_id: OrderId,
                          disposition_desc: null
                        })
                      }}>Hold Order</Button>
                    ) : "" : ""
                  }
                  {
                    actions.includes('dispatch-order=>') ? (!OrderDetails.tbl_order_room && OrderDetails.status === "verify") ? (
                      <Button color='success' variant='contained' onClick={() => {
                        setUpdateOpen(true)
                        setUpdatebaleData({
                          status: "dispatch",
                          dispatched_at: moment().format().split(":")[0] + ":" + moment().format().split(":")[1],
                          title: `Dispatch Order : ${OrderId}`,
                          order_id: OrderId,
                          disposition_desc: null,
                          crmid: OrderDetails.crmid || null
                        })
                      }}>Dispatch Order</Button>
                    ) : "" : ""
                  }
                  {
                    actions.includes("cancel-order=>") ? (!OrderDetails.status || OrderDetails.status === "verify") || OrderDetails.status === "hold" ? (
                      <Button color='error' variant='contained' onClick={() => {
                        setUpdateOpen(true)
                        setUpdatebaleData({
                          status: "cancel",
                          title: `Cancel Order : ${OrderId}`,
                          order_id: OrderId,
                          disposition_desc: OrderDetails.disposition_desc || null,
                        })
                      }}>Cancel Order</Button>
                    ) : "" : ""
                  }
                  {
                    actions.includes("update-transit-status=>") ? (OrderDetails.status === "dispatch") ? (
                      <Button color='secondary' variant='contained' onClick={() => {
                        setUpdateOpen(true)
                        setUpdatebaleData({
                          status: "update_transit_status",
                          disposition_desc: null,
                          order_id: OrderId,
                          title: `Update transit status : ${OrderId}`,
                          delivered_at: moment().format().split(":")[0] + ":" + moment().format().split(":")[1]
                        })
                      }} >Update transit status</Button>
                    ) : "" : ""
                  }
                </div>
              </>
            ) : ""
          }
        </div>
      </div>
    </>
  )
}
