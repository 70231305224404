import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Loader from '../../Components/Loader';
import ApiHelper from '../../Helper/ApiHelper';

export default function UpdateProduct(props) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const { open, setOpen, refetchOrder, ProductDetails, setProductDetails } = props
    const [Loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false);
    };


    const UpdateHendler = async () => {
        const data = { ...ProductDetails }
        if (!data.qty || !Number(data.qty)) {
            return showSnackbar("Required Feild Quantity Is Empty", { variant: "error" })
        }
        if (!data.total_price || !Number(data.total_price)) {
            return showSnackbar("Required Feild Quantity Is Empty", { variant: "error" })
        }
        if (`${Number(data.token_amount)}` === "NaN") {
            return showSnackbar("Token Amount Must Be Number", { variant: "error" })
        }
        try {
            setLoading(true)
            await ApiHelper.UpdateProductDetails(data)
            setLoading(false)
            showSnackbar("Product Updated Successfully", { variant: "success" })
            refetchOrder()
            setOpen(false)
        } catch (err) {
            setLoading(false)
            showSnackbar("Failed To Update Product", { variant: "error" })
        }
    }


    return (
        <div>
            {
                Loading ? <Loader /> : ""
            }
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Update Product Information"}
                </DialogTitle>
                <hr className='my-0' />
                <DialogContent>
                    <Typography className='text-muted fw-bold'>Product Name <span style={{ fontSize: "0.7rem" }} className=' text-danger fw-lighter'>(READ ONLY)</span></Typography>
                    <input value={ProductDetails.name} type="text" disabled />
                    {/* <Typography className='text-muted fw-bold mt-2'>Quantity</Typography>
                    <select value={ProductDetails.qty} onChange={(e) => setProductDetails({ ...ProductDetails, total_price: ProductDetails.total_price / ProductDetails.qty * Number(e.target.value), qty: e.target.value })}>
                        {
                            [...Array(11).keys()].map((x) => (
                                <option key={x} className='text-center' disabled={!x > 0} value={x}>{x}</option>
                            ))
                        }
                    </select> */}
                    <Typography className='text-muted fw-bold mt-2'>Price</Typography>
                    <input type="text" value={ProductDetails.total_price} onChange={(e) => setProductDetails({ ...ProductDetails, total_price: e.target.value })} />
                </DialogContent>
                <hr className='mt-0 mb-2' />
                <DialogActions className='justify-content-between orderDetailsActions px-4 pb-3'>
                    <Button color='error' className='w-50' variant='contained' onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' className='w-50' variant='contained' onClick={UpdateHendler} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
