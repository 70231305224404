import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ApiHelper from '../../Helper/ApiHelper';
import Loader from '../../Components/Loader';
import moment from 'moment';

export default function UpdateOrder(props) {
    const [CourierList, setCourierList] = React.useState([])
    const [transitStatus, settransitStatus] = React.useState("")
    const { open, setOpen, data, setdata, refetchOrder, UserInfo } = props
    const [Loading, setLoading] = React.useState(false)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const location = useLocation()
    const isNewOrder = location.search.split("?new-order=")[1] === "true" ? true : false

    const handleClose = () => {
        setdata({})
        setOpen(false);
    };

    const FetchCourierPartners = async () => {
        try {
            setLoading(true)
            const Result = await ApiHelper.FetchCourierServices()
            setCourierList(Result.data.CourierPartners)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            showSnackbar("Failed To Fetch Courier Parners", { variant: "error" })
        }
    }

    React.useEffect(() => {
        FetchCourierPartners()
        // eslint-disable-next-line 
    }, []);


    const UpdateHendler = async () => {
        if (data.status === "dispatch") {
            let Data = { ...data }
            if (!Data.tracking_id || Data.tracking_id === "") {
                return showSnackbar("Required Field Tracking Id Is Empty", { variant: "error" })
            }
            if (!Data.courier_partner_id) {
                return showSnackbar("Required Field Courier Partner Is Empty", { variant: "error" })
            }
            if (!Data.dispatched_at) {
                return showSnackbar("Required Field Dispatched At Is Empty", { variant: "error" })
            }
            Data.dispatched_at = Data.dispatched_at + ":00.000Z"
            Data.dispatched_at = moment(Data.dispatched_at).format()
            Data.order_id = Number(Data.order_id)
            Data.courier_partner_id = Number(Data.courier_partner_id)
            // return console.log(Data);
            try {
                setLoading(true)
                await ApiHelper.Updatedisposition(Data)
                setLoading(false)
                refetchOrder()
                setOpen(false)
                showSnackbar("Order Updated Successfully", { variant: "success" })
                console.log(localStorage.lastUrl)
                window.location = localStorage.lastUrl ? localStorage.lastUrl : window.location.href.split("?")[0]
            } catch (err) {
                setLoading(false)
                showSnackbar("Failed To Update Order", { variant: "error" })
            }
        }
        if (data.status === "cancel") {
            let Data = { ...data }
            if (!Data.disposition_desc || Data.disposition_desc === "") {
                return showSnackbar("Requried Field Reason For Cancellation Is Empty", { variant: 'error' })
            }
            Data["crmid"] = null
            Data["courier_partner_id"] = null
            Data["tracking_id"] = null
            Data["dispatched_at"] = null
            Data.order_id = Number(Data.order_id)
            try {
                setLoading(true)
                await ApiHelper.Updatedisposition(Data)
                setLoading(false)
                refetchOrder()
                setOpen(false)
                showSnackbar("Order Updated Successfully", { variant: "success" })
                window.location = localStorage.lastUrl ? localStorage.lastUrl : window.location.href.split("?")[0]
            } catch (err) {
                setLoading(false)
                showSnackbar("Failed To Update Order", { variant: "error" })
            }
        }
        if (data.status === "verify") {
            let Data = { ...data }
            if (!Data.crmid || Data.crmid === "") {
                return showSnackbar("Requried Field CRM ID Is Empty", { variant: "error" })
            }
            if (`${Number(Data.crmid)}` === "NaN") {
                return showSnackbar("CRM ID Must Be A Number", { variant: "error" })
            }
            Data["courier_partner_id"] = null
            Data["tracking_id"] = null
            Data["disposition_desc"] = null
            Data["dispatched_at"] = null
            Data.order_id = Number(Data.order_id)
            Data.crmid = Number(Data.crmid)
            try {
                setLoading(true)
                await ApiHelper.Updatedisposition(Data)
                setLoading(false)
                refetchOrder()
                setOpen(false)
                showSnackbar("Order Updated Successfully", { variant: "success" })
                window.location = localStorage.lastUrl ? localStorage.lastUrl : window.location.href.split("?")[0]
            } catch (err) {
                setLoading(false)
                showSnackbar("Failed To Update Order", { variant: "error" })
            }
        }
        if (data.status === "update_transit_status") {
            let Data = { ...data, location: transitStatus }
            if (transitStatus === "" || !transitStatus) {
                return showSnackbar("Required Field Transit Status Is Emtpy", { variant: 'error' })
            }
            if (transitStatus === "returned") {
                if (!Data.disposition_desc || Data.disposition_desc === "") {
                    return showSnackbar("Reuqired Field Ression For Returned Is Empty", { variant: "error" })
                }
            }
            if (transitStatus === "delivered") {
                if (!Data.delivered_at || Data.delivered_at === "") {
                    return showSnackbar("Reuqired Field Delivered At Is Empty", { variant: "error" })
                }
            }
            if (transitStatus === "delivered") {
                Data.delivered_at = Data.delivered_at + ":00.000Z"
                Data.delivered_at = moment(Data.delivered_at).format()
            }
            if (transitStatus === "returned") {
                Data.delivered_at = null
            }
            Data.order_id = Number(Data.order_id)
            try {
                setLoading(true)
                await ApiHelper.UpdateTransitStatus(Data)
                setLoading(false)
                refetchOrder()
                setOpen(false)
                showSnackbar("Order Updated Successfully", { variant: "success" })
                window.location = localStorage.lastUrl ? localStorage.lastUrl : window.location.href
            } catch (err) {
                setLoading(false)
                showSnackbar("Failed To Update Order", { variant: "error" })
            }
        }

    }


    const InsertHenlder = async () => {
        let Data = { ...data }
        delete Data.title
        if (data.status === "verify") {
            data.disposition_desc = null
            if (!data.crmid || data.crmid === null || data.crmid === "") {
                return showSnackbar("Required Field CRM ID Is Empty", { variant: "error" })
            }
            if (`${Number(data.crmid)}` === "NaN") {
                return showSnackbar("CRM ID Must Be A Number", { variant: "error" })
            }
            Data.crmid = Number(Data.crmid)
        }
        if (data.status === "hold") {
            Data["crmid"] = null
            if (!data.disposition_desc || data.disposition_desc === "") {
                return showSnackbar("Reuired Field Reason For Hold Is Emtpy", { variant: 'error' })
            }
        }
        if (data.status === "cancel") {
            Data['crmid'] = null
            if (!data.disposition_desc || data.disposition_desc === "") {
                return showSnackbar("Reuired Field Reason For Hold Is Emtpy", { variant: 'error' })
            }
        }

        try {
            Data.order_id = Number(Data.order_id)
            Data["last_process_by"] = UserInfo ? UserInfo.id : null
            setLoading(true)
            const Result = await ApiHelper.InsertOrderRoom(Data)
            setLoading(false)
            if (Result.status === 200) {
                setOpen(false)
                refetchOrder()
                window.location = localStorage.lastUrl ? localStorage.lastUrl : window.location.href.split("?")[0]
                return showSnackbar('Order Updated Successfully', { variant: "success" })
            }
        } catch (err) {
            setLoading(false)
            console.log(err);
            return showSnackbar("Failed To Update Order", { variant: "error" })
        }

    }

    const [width, setwidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setwidth(window.innerWidth)
        })
    }, [width]);


    return (
        <div>
            {
                Loading ? (
                    <Loader />
                ) : ""
            }
            <Dialog open={open} sx={{
                ".MuiPaper-root": {
                    maxWidth: "100vw",
                    minWidth: width > 598 ? "35%" : "85%",
                    margin: width < 598 ? "10px" : "32px"
                }
            }}>
                <DialogTitle className='mb-3' style={{ borderBottom: "1px solid #5048e5" }} >
                    <span className='fs-5 fw-bold' >
                        {open ? data.title.split(":")[0] : ""}:
                    </span><span className='fs-6 fw-bold text-muted'>
                        {open ? data.title.split(":")[1] : ""}
                    </span>
                </DialogTitle>
                <DialogContent>
                    {
                        data.status === "verify" ? (
                            <>
                                <Typography variant='subtitle1' className='fw-bold text-muted'>CRM ID</Typography>
                                <input onChange={(e) => setdata({ ...data, crmid: e.target.value })} placeholder='Enter CRM ID' />
                            </>
                        ) : ""
                    }
                    {
                        data.status === "hold" ? (
                            <>
                                <Typography variant='subtitle1' className='fw-bold text-muted'>Reason For Hold</Typography>
                                <input onChange={(e) => setdata({ ...data, disposition_desc: e.target.value })} placeholder='Enter Reason For Hold' />
                            </>
                        ) : ""
                    }
                    {
                        data.status === "cancel" ? (
                            <>
                                <Typography variant='subtitle1' className='fw-bold text-muted'>Reason For Cancellation</Typography>
                                <input onChange={(e) => setdata({ ...data, disposition_desc: e.target.value })} placeholder='Enter Reason For Cancellation' />
                            </>
                        ) : ""
                    }
                    {
                        data.status === 'dispatch' ? (
                            <>
                                <Typography variant='subtitle1' className="fw-bold text-muted" >Courier Partner</Typography>
                                <select onChange={(e) => setdata({ ...data, courier_partner_id: e.target.value })}>
                                    <option value={undefined}>Select Courier Partner</option>
                                    {
                                        CourierList.length ? CourierList.map((x, key) => (
                                            <option key={key} value={x.courier_partner_id}>{x.alias}</option>
                                        )) : ""
                                    }
                                </select>
                                <Typography variant='subtitle1' className=' mt-2 fw-bold text-muted'>Tracking Id</Typography>
                                <input onChange={(e) => setdata({ ...data, tracking_id: e.target.value })} type="text" placeholder='Enter Tracking Id' />
                                <Typography variant='subtitle1' className=' mt-2 fw-bold text-muted'>Dispatched At</Typography>
                                <input value={data.dispatched_at} onChange={(e) => setdata({ ...data, dispatched_at: e.target.value })} type="datetime-local" placeholder='Enter Tracking Id' />
                            </>
                        ) : ""
                    }
                    {
                        data.status === "update_transit_status" ? (
                            <>
                                <Typography variant='subtitle1' className='text-muted fw-bold'>Transit Status</Typography>
                                <select onChange={(e) => settransitStatus(e.target.value)}>
                                    <option value={undefined}>Select Transit Status</option>
                                    <option value={"delivered"}>Delivered</option>
                                    <option value={"returned"}>Returned</option>
                                </select>
                                {
                                    transitStatus === "delivered" ? (
                                        <>
                                            <Typography variant='subtitle1' className='text-muted fw-bold mt-2'>Delivered At</Typography>
                                            <input type="datetime-local" value={data.delivered_at} onChange={(e) => setdata({ ...data, delivered_at: e.target.value })} />
                                        </>
                                    ) : transitStatus === "returned" ? (
                                        <>
                                            <Typography variant='subtitle1' className='text-muted fw-bold mt-2'>Reason For Return</Typography>
                                            <input type="text" onChange={(e) => setdata({ ...data, disposition_desc: e.target.value })} placeholder='Enter Reason For Return' />
                                        </>
                                    ) : ""
                                }
                            </>
                        ) : ""
                    }

                </DialogContent>
                <DialogActions className='w-100 d-flex justify-content-between px-3 orderDetailsActions' style={{ columnGap: "1rem" }}>
                    <Button className='w-50' onClick={handleClose} variant="contained" color='error'>Cancel</Button>
                    <Button className='w-50 m-0' onClick={isNewOrder ? InsertHenlder : UpdateHendler} variant='contained' color={data.status === "verify" ? 'info' : data.status === "hold" ? "warning" : data.status === "cancel" ? "secondary" : data.status === "dispatch" ? "success" : "secondary"}>{data.status === "verify" ? "Accept Order" : data.status === "hold" ? " Hold Order" : data.status === "cancel" ? "Cancel Order" : data.status === "dispatch" ? "Dispatch Order" : "Update Status"}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
